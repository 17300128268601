import { SystemStyleObject, mode } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {};

const sizes: Record<string, SystemStyleObject> = {};

const variants = {
  normal: {
    color: "gray.700",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    paddingTop: 1,
  },
  nourish: {
    color: "gray.700",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    mb: "2px",
  },
  gray: (props: any) => ({
    color: mode("gray.700", "gray.200")(props),
  }),
};

const defaultProps = {
  variant: "normal",
};

const FormLabel = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};

export default FormLabel;
